<template>
  <div class="body_bgi box_1 full bgs_full relative " ref="home_box">
    <div class="full jian_bian_bgc">
      <!-- header -->
      <div class="header_bgi2 w_full h_100 bgs_full ph_20 border_box relative ">
        <div class="fw_bold fs_30 text_right ls_10 absolute left_center top_20 color_fff font_zh cursor">
          向北开放重要桥头堡大数据平台
        </div> 
      </div>

      <!-- body -->
      <div class="w_full-60 h_full-130 m_0_auto relative">
        <div class="full">
          <word-map-2 
            :heightLightArea="heightLightArea" 
            :lines="lines"
            :effectScatter="effectScatter" 
          />
        </div>

        <div class="w_400 h_60_p flex_column_start gap_20 pl_10 absolute left_0 bottom_100 bgc_fbf9f8b5 border_radius_10 p_10">
          <div class="w_350 h_50 flex_row_start flex_center p_10">
            HS编码： 
            <div class="w_250">
              <el-input 
                v-model="hsCode" clearable placeholder="请输入HS编码" 
                @keyup.enter.native="russiaQueryHs6()" 
                @clear="clearHs6Value"
              >
                <el-button slot="append" icon="el-icon-search" @click="russiaQueryHs6()"></el-button>
              </el-input>
            </div>
          </div>

          <div class="w_350 flex_wrap p_10" v-if="product && product.description">
            商品名称： {{ product.description }}
          </div>

          <div class="w_350 h_50 p_10">
            <div class="w_full h_full flex_center cursor">
              对俄不友好国家: 
              <el-select 
                class="ml_10"
                :class="selectedChina(country1)"
                v-model="country1" 
                size="small"
                clearable filterable 
                placeholder="请选择国家"
                @clear="clearCountry1">
                <el-option
                  :class="unfriendlyCountryStyle(item)"
                  v-for="(item, index) in countryList1" :key="index"
                  :label="item"
                  :value="item"
                  @click.native="selectCountry1(item)">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="w_350 50 p_10">
            <div class="w_full h_full flex_center cursor">
              转口贸易国家: 
              <el-select 
                class="ml_25"
                :class="selectedChina(country2)"
                v-model="country2" 
                size="small"
                clearable filterable 
                placeholder="请选择国家"
                @clear="clearCountry2">
                <el-option
                  :class="unfriendlyCountryStyle(item)"
                  v-for="(item, index) in countryList2" :key="index"
                  :label="item.maoyi"
                  :value="item.maoyi"
                  @click.native="selectCountry2(item)">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="w_150 h_50 flex_row_start flex_center gap_5">
            <div 
              class="w_70 h_35 lh_35 bgc_fff border_radius_5 text_center cursor"
              :class="{ 'bgc_0874EF color_fff': activeType == '进口' }"
              @click="clickTypeBtn('进口')">进口
            </div>
            <div 
              class="w_70 h_35 lh_35 bgc_fff border_radius_5 text_center ml_10 cursor"
              :class="{ 'bgc_d21e42 color_fff': activeType == '出口' }"
              @click="clickTypeBtn('出口')">出口
            </div>
          </div>

          <!-- 进口额 -->
          <div class="w_full p_10" v-if="country2 && activeType == '进口'">
            <div>
              俄罗斯 自 <span class="color_0874EF">{{ country1 }}</span> 进口额: 
              <span class="fs_20 fw_bold font_number color_0874EF">{{ formatNumberWithCommas(activeData.eed2022russia) }}</span>
              美元
            </div>
            <div>
              <span class="color_0874EF">{{ country2 }}</span> 自 <span class="color_d21e42">{{ country1 }}</span> 进口额: 
              <span class="fs_20 fw_bold font_number color_0874EF">{{ formatNumberWithCommas(activeData.eed2022maoyi) }}</span>
              美元
            </div>
            <div>
              俄罗斯 自 <span class="color_d21e42">{{ country2 }}</span> 进口额: 
              <span class="fs_20 fw_bold font_number color_0874EF">{{ formatNumberWithCommas(activeData.eed2022maoyiRussia) }}</span>
              美元
            </div>
          </div>

          <!-- 出口额 -->
          <div class="w_full p_10" v-if="country2 && activeType == '出口'">
            <div>
              俄罗斯 向 <span class="color_0874EF">{{ country1 }}</span> 出口额: 
              <span class="fs_20 fw_bold font_number color_d21e42">{{ formatNumberWithCommas(activeData.ied2022russia) }}</span>
              美元
            </div>
            <div>
              俄罗斯 向 <span class="color_d21e42">{{ country2 }}</span> 出口额: 
              <span class="fs_20 fw_bold font_number color_d21e42">{{ formatNumberWithCommas(activeData.ied2022maoyiRussia) }}</span>
              美元
            </div>
            <div>
              <span class="color_d21e42">{{ country2 }}</span> 向 <span class="color_0874EF">{{ country1 }}</span> 出口额: 
              <span class="fs_20 fw_bold font_number color_d21e42">{{ formatNumberWithCommas(activeData.ied2022maoyi) }}</span>
              美元
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defMixin from '@/mixins/def';
import componentsMixin from '@/mixins/components';

import {
  getProductInfo,
  rtiQueryHs6,
  rteQueryHs6,
} from '@/api/base/index.js'

import { Message } from 'element-ui'

export default {
  name: 'RussiaNew',
  mixins: [defMixin, componentsMixin],
  data() {
    return {
      hsCode: '',

      country1: '',
      country2: '',

      activeType: '进口',
      activeData: {},

      product: {},

      russiaDataList: [],

      // 对俄不友好国家
      countryList1: [],
      // 转口贸易国家
      countryList2: [],

      heightLightArea: [],
      lines: [],
      effectScatter: [],
    }
  },
  async mounted() {
    let hsCode = this.$route.params.hsCode
    this.hsCode = hsCode

    await this.russiaQueryHs6(hsCode)

    setTimeout(() => {
      if (this.countryList2 && this.countryList2.length) {
        this.country2 = this.countryList2[0].maoyi
        this.selectCountry2(this.countryList2[0])
      }
    }, 500)
  },
  destroyed() {

  },
  methods: {
    selectedChina(val) {
      if (val == '中国') {
        return 'china_text_style'
      }
    },

    unfriendlyCountryStyle(val) {
      let unfriendlyCountry = [
        '保加利亚', '塞浦路斯', '克罗地亚', '丹麦', '芬兰', '法国', '希腊', '匈牙利', '爱尔兰', '卢森堡', 
        '马耳他', '葡萄牙', '罗马尼亚', '斯洛文尼亚', '西班牙', '瑞典', '乌克兰', '澳大利亚', '新西兰', '新加坡', 
        '黑山', '阿尔巴尼亚', '挪威', '冰岛', '安道尔', '北马其顿共和国', '中国台湾'
      ]
      if (unfriendlyCountry.includes(val.name)) {
        return 'unfriend_country'
      }
    },

    // 选择 对俄不友好国家
    selectCountry1(item) {
      this.country2 = ''

      this.countryList2 = this.russiaDataList.filter(data => data.cou == item)

      this.clearMap()
    },

    // 选择 转口 贸易国家
    selectCountry2(item) {
      this.activeData = item

      // 1. 高亮 国家 区域
      this.heightLightArea = ['俄罗斯', this.country1, this.country2]

      let line1 = {}
      let line2 = {}
      let line3 = {}

      // 2. 在地图 上显示 3 个 方向的 飞线
      if (this.activeType == '进口') {
        line1 = {
          form: this.country1,
          to: '俄罗斯',
          value: item.eed2022russia
        }
        line2 = {
          form: this.country1,
          to: this.country2,
          value: item.eed2022maoyi
        }
        line3 = {
          form: this.country2,
          to: '俄罗斯',
          value: item.eed2022maoyiRussia
        }
      } else {
        line1 = {
          form: '俄罗斯',
          to: this.country1,
          value: item.ied2022russia
        }
        line2 = {
          form: '俄罗斯',
          to: this.country2,
          value: item.ied2022maoyiRussia
        }
        line3 = {
          form: this.country2,
          to: this.country1,
          value: item.ied2022maoyi
        }
      }

      this.lines = [ line1, line2, line3 ]

      // 3. 显示 涟漪
      this.effectScatter = ['俄罗斯', this.country1, this.country2]
    },

    clearCountry1() {
      this.country1 = ''
      this.clearCountry2()

      this.clearMap()
    },

    clearCountry2() {
      this.country2 = ''

      this.clearMap()
    },

    // 【进口】【出口】
    clickTypeBtn(val) {
      this.countryList1 = []
      this.countryList2 = []

      this.country1 = ''
      this.country2 = ''

      this.clearMap()

      this.activeType = val

      if (this.hsCode) {
        if (val == '进口') {
          this.rtiQueryHs6()
        } else {
          this.rteQueryHs6()
        }
      } else {
        Message({
          message: '请输入 HS 编码',
          type: 'error',
          duration: 1 * 1000
        })
      }
    },

    // 调用接口 
    russiaQueryHs6() {
      let params_hscode = this.$route.params.hsCode
      if (params_hscode != this.hsCode) {
        this.$router.replace({ path: `/russia/${this.hsCode}`})
      }

      this.getProductInfo(this.hsCode)

      if (this.hsCode) {
        if (this.activeType == '进口') {
          this.rtiQueryHs6()
        } else {
          this.rteQueryHs6()
        }
      } else {
        Message({
          message: '请输入 HS 编码',
          type: 'error',
          duration: 1 * 1000
        })
      }
    },

    // 获取 商品 信息
    async getProductInfo(hsCode) {
      this.product = {}
      let res = await getProductInfo(hsCode)
      try {
        if (res.code == 1000) {
          this.product = res.data
        }
      } catch(err) {
        console.log('获取商品信息失败', err);
      }
    },

    // 获取 俄罗斯 进口
    async rtiQueryHs6() {
      let res = await rtiQueryHs6(this.hsCode)
      try {
        if (res.code == 1000) {
          this.russiaDataList = res.data

          let cou_country_list = []
          res.data.map(item => cou_country_list.push(item.cou))

          this.countryList1 = [...new Set(cou_country_list)]
          this.country1 = this.countryList1[0]

          let cou = res.data[0].cou
          let countryList2 = res.data.filter(item => item.cou == cou)
          this.countryList2 = countryList2

          // if (countryList2.length) {
          //   this.clickCountry2(countryList2[0])
          // }
        }
      } catch(err) {
        console.log('获取俄罗斯进口总额: ', err);
      }
    },

    // 获取 俄罗斯 出口
    async rteQueryHs6() {
      let res = await rteQueryHs6(this.hsCode)
      try {
        if (res.code == 1000) {
          this.russiaDataList = res.data

          let cou_country_list = []
          res.data.map(item => cou_country_list.push(item.cou))

          this.countryList1 = [...new Set(cou_country_list)]
          this.activeCountry1 = this.countryList1[0]

          let cou = res.data[0].cou
          let countryList2 = res.data.filter(item => item.cou == cou)
          this.countryList2 = countryList2

          // if (countryList2.length) {
          //   this.clickCountry2(countryList2[0])
          // }
        }
      } catch(err) {
        console.log('获取俄罗斯出口总额: ', err);
      }
    },

    // 清除 地图上的 效果
    clearMap() {
      this.heightLightArea = []
      this.lines = []
      this.effectScatter = []
    },

    clearHs6Value() {
      this.hsCode = ''
      this.product = {}

      this.countryList1 = []
      this.countryList2 = []

      this.country1 = ''
      this.country2 = ''
    },

    // 跳转页面
    goToPage(path) {
      this.$router.push(path)
    }

  }
}
</script>

<style>
  .china_text_style > .el-input > input { color: #ce1126 !important; }
  .unfriend_country > span { color: #ccc !important; }
</style>

